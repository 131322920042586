<template>
  <v-card :loading="loading" class="h-full container-wrap" justify="center" width="580px">
    <v-card-text class="card-text">
      <v-row dense style="min-height: 110px" align="center" justify="center">
        <!-- Info -->
        <v-col cols="5" style="padding-right: 3px" align="start">
          <div class="info-box">
            <p>
              <strong>{{ data.address }}</strong>
            </p>
            <p>{{ data.zipCode }}, {{ data.city }}</p>
            <p>
              {{ data.houseType }}<span v-if="data.houseType && data.condominium.buildYear">, </span
              >{{ data.condominium.buildYear }}
            </p>
          </div>
        </v-col>

        <!-- Rent -->
        <v-col
          cols="3"
          style="padding-right: 3px; padding-left: 5px"
          class="details-box"
          align="start"
        >
          <p style="margin-bottom: -1px; font-weight: 600">{{ data.area }} m²</p>
          <p>{{ data.floorPlan }}</p>
        </v-col>

        <!-- Price -->
        <v-col cols="4" style="padding-right: 15px; padding-left: 15px" align="center">
          <p v-if="data.estimatedRent" :style="{ color: settings.accentColor }" class="price">
            {{ formatCardPrice(data.estimatedRent) }}/kk
          </p>

          <p v-else class="price">-</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState } from "vuex";

export default {
  props: ["data"],

  mixins: [mixins],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState("account", ["settings"]),
  },
};
</script>

<style scoped>
.card-text {
  font-size: 180%;
}

.info-box {
  padding-left: 25px;
  font-size: 15px;
}

.details-box {
  font-size: 15px;
}

.price {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

@media only screen and (max-width: 600px) {
  .v-application .container-wrap {
    font-size: 82%;
  }

  .price {
    font-size: 14px;
  }

  .details-box,
  .info-box {
    font-size: 12px;
  }

  .info-box {
    padding-left: 0px;
  }
}
</style>
