<template>
  <div>
    <h3 class="mb-1 mt-2" :style="{ color: settings.contactFieldTextColor }">
      Jätä yhteystietosi niin olemme yhteydessä heti kun sopivia vuokrakohteita vapautuu
    </h3>

    <v-form ref="form" v-model="valid" lazy-validation>
      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Nimi</strong>
      </p>
      <v-text-field
        v-model="fullName"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        :rules="required"
        outlined
        dense
        hide-details
      ></v-text-field>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Email</strong>
      </p>
      <v-text-field
        v-model="email"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        :rules="emailRules"
        outlined
        dense
        hide-details
      ></v-text-field>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Puhelin</strong>
      </p>
      <v-text-field
        v-model="phone"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        outlined
        dense
        hide-details
      ></v-text-field>

      <div
        v-if="
          settings.emptyResultsFormFields.includes('allocation') &&
          settings.emptyResultsFormValues.allocation
        "
      >
        <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
          <strong>Osuus vuokrakohteesta</strong>
        </p>
        <v-checkbox
          v-for="(checkbox, index) in getCustomValues(settings.emptyResultsFormValues.allocation)"
          :key="index + 'a'"
          v-model="allocation"
          :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
          :color="settings.contactFieldTextColor"
          :label="checkbox"
          :value="checkbox"
          class="shrink mr-0 ml-0 mt-0"
          outlined
          hide-details
        ></v-checkbox>
      </div>

      <div
        v-if="
          settings.emptyResultsFormFields.includes('area') && settings.emptyResultsFormValues.area
        "
      >
        <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
          <strong>Vuokrakohteen koko</strong>
        </p>
        <v-checkbox
          v-for="(checkbox, index) in getCustomValues(settings.emptyResultsFormValues.area)"
          :key="index + 'a'"
          v-model="area"
          :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
          :color="settings.contactFieldTextColor"
          :label="checkbox"
          :value="checkbox"
          class="shrink mr-0 ml-0 mt-0"
          outlined
          hide-details
        ></v-checkbox>
      </div>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Viesti</strong>
      </p>
      <v-textarea
        v-model="message"
        class="mb-3"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        placeholder="Kirjoita muut toiveesi vuokrakohteeseen liittyen..."
        outlined
        dense
        hide-details
      ></v-textarea>
      <div style="position: absolute; top: -9999em; left: -9999em">
        <v-text-field v-model="name" label="Name"></v-text-field>
        <v-checkbox v-model="termsAgreeCheck" label="Agree terms"></v-checkbox>
      </div>
    </v-form>

    <v-btn
      :disabled="!valid"
      :color="settings.actionBtnColor"
      class="white--text mb-3"
      @click.prevent="submit"
    >
      Lähetä
    </v-btn>

    <!-- Message texts -->
    <div
      v-if="sendStatus.message"
      class="message-box"
      :style="{
        border: sendStatus.error
          ? `1px solid ${settings.errorColor}`
          : `1px solid ${settings.successColor}`,
      }"
    >
      <p :style="{ color: sendStatus.error ? settings.errorColor : settings.successColor }">
        {{ sendStatus.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data: () => ({
    valid: true,
    nameRules: [(v) => !!v || "Nimi on pakollinen"],
    emailRules: [
      (v) => !!v || "Email on pakollinen",
      (v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa",
    ],
    required: [(v) => !!v || "Pakollinen kenttä"],
  }),

  created() {
    this.$store.state.contact.sendStatus.message = "";
    this.$store.state.contact.sendStatus.error = false;
  },

  computed: {
    ...mapState("account", ["settings", "colors"]),
    ...mapState("contact", ["sendStatus"]),

    fullName: {
      get() {
        return this.$store.state.contact.emptyResultsForm.fullName;
      },
      set(val) {
        this.setFormField({ val, field: "fullName", form: "emptyResultsForm" });
      },
    },
    email: {
      get() {
        return this.$store.state.contact.emptyResultsForm.email;
      },
      set(val) {
        this.setFormField({ val, field: "email", form: "emptyResultsForm" });
      },
    },
    phone: {
      get() {
        return this.$store.state.contact.emptyResultsForm.phone;
      },
      set(val) {
        this.setFormField({ val, field: "phone", form: "emptyResultsForm" });
      },
    },
    allocation: {
      get() {
        return this.$store.state.contact.emptyResultsForm.allocation;
      },
      set(val) {
        this.setFormField({ val, field: "allocation", form: "emptyResultsForm" });
      },
    },
    area: {
      get() {
        return this.$store.state.contact.emptyResultsForm.area;
      },
      set(val) {
        this.setFormField({ val, field: "area", form: "emptyResultsForm" });
      },
    },
    message: {
      get() {
        return this.$store.state.contact.emptyResultsForm.message;
      },
      set(val) {
        this.setFormField({ val, field: "message", form: "emptyResultsForm" });
      },
    },

    // HIDDEN FIELD
    name: {
      get() {
        return this.$store.state.contact.emptyResultsForm.name;
      },
      set(val) {
        this.setFormField({ val, field: "name", form: "emptyResultsForm" });
      },
    },

    // Check fields
    termsAgreeCheck: {
      get() {
        return this.$store.state.contact.emptyResultsForm.termsAgreeCheck;
      },
      set(val) {
        this.setFormField({ val, field: "termsAgreeCheck", form: "emptyResultsForm" });
      },
    },
  },

  methods: {
    ...mapActions("contact", ["submitEmptyResultForm"]),
    ...mapMutations("contact", ["setFormField", "setStatus"]),

    async submit() {
      if (this.$refs.form.validate()) {
        if (!this.termsAgreeCheck && (this.name == "" || !this.name)) {
          try {
            await this.submitEmptyResultForm(this.$store.state.contact.emptyResultsForm);

            this.setStatus({
              message: "Kiitos yhteydenotosta! Viestin lähetys onnistui.",
              error: false,
            });

            // Reset form
            this.$refs.form.reset();
          } catch (err) {
            this.setStatus({
              message: "Tapahtui virhe! Yritä myöhemmin uudelleen tai ota yhteyttä puhelimitse.",
              error: true,
            });
          }
        }
      }
    },

    getCustomValues(val) {
      const values = val.split("\n");
      return values;
    },
  },
};
</script>

<style scoped src="../../assets/css/contactForm.css"></style>
