<template>
  <div
    :style="{
      background: settings.searchBackgroundColor,
    }"
  >
    <v-row v-show="showSearchForm" class="py-3 px-sm-2" dense no-gutters justify="center">
      <v-col cols="12" md="12" lg="9" justify="center">
        <v-form ref="form">
          <v-row dense class="ma-1" justify="center">
            <!-- Address -->
            <v-col
              v-show="settings.searchComponentItems.includes('freeText') || showLocation"
              cols="12"
              sm="6"
              md="4"
              order="1"
              order-sm="1"
              order-md="1"
            >
              <div v-show="showLocation">
                <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                  <strong>Sijainti</strong>
                </p>
                <v-autocomplete
                  ref="location_field"
                  class="my-field"
                  v-model="location"
                  :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                  :items="locations"
                  :item-text="formattedLocation"
                  :item-value="JSON.stringify(location)"
                  outlined
                  dense
                  hide-details
                  item-color=""
                  clearable
                  @change="focusOut"
                >
                </v-autocomplete>
              </div>

              <div v-show="settings.searchComponentItems.includes('freeText')">
                <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                  <strong>Sanahaku</strong>
                </p>
                <v-text-field
                  ref="freeText_field"
                  v-model="freeText"
                  :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                  outlined
                  dense
                  hide-details
                  @keyup.enter="
                    setPage(1);
                    search();
                  "
                >
                </v-text-field>
              </div>
            </v-col>

            <!-- HouseType -->
            <v-col
              v-show="
                settings.searchComponentItems.includes('houseType') ||
                settings.searchComponentItems.includes('roomCount')
              "
              cols="12"
              md="4"
              order="3"
              order-sm="3"
              order-md="2"
            >
              <v-row no-gutters class="mb-2">
                <v-col v-show="settings.searchComponentItems.includes('houseType')">
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Talotyyppi</strong>
                  </p>
                  <v-checkbox
                    v-for="(checkbox, index) in settings.houseTypeItems"
                    :key="index + 'a'"
                    v-model="houseType"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    :color="settings.fieldColor"
                    :label="checkbox"
                    class="shrink mr-0 ml-0 mt-0"
                    :value="checkbox"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col v-show="settings.searchComponentItems.includes('roomCount')" class="pl-3">
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Huoneluku</strong>
                  </p>
                  <v-checkbox
                    v-for="checkbox in 5"
                    :key="checkbox + 'b'"
                    v-model="roomCount"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    :color="settings.fieldColor"
                    :label="checkbox < 5 ? String(checkbox) : String(checkbox + '+')"
                    class="shrink mr-0 ml-0 mt-0"
                    :value="checkbox"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <!-- AREA -->
            <v-col
              v-show="showArea || showEstimatedRent"
              cols="12"
              sm="6"
              md="4"
              order="2"
              order-sm="2"
              order-md="3"
            >
              <v-row v-show="showArea" no-gutters>
                <v-col class="mr-1">
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Pinta-ala min</strong>
                  </p>
                  <v-text-field
                    v-model.number="areaMin"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    type="number"
                    placeholder="Min"
                    suffix="m²"
                    outlined
                    dense
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col>
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Pinta-ala max</strong>
                  </p>
                  <v-text-field
                    v-model.number="areaMax"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    type="number"
                    placeholder="Max"
                    suffix="m²"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field
                ></v-col>
              </v-row>

              <!-- ESTIMATED RENT -->
              <v-row v-show="showEstimatedRent" no-gutters>
                <v-col class="mr-1">
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Vuokra min</strong>
                  </p>
                  <v-text-field
                    v-model.number="estimatedRentMin"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    type="number"
                    placeholder="Min"
                    suffix="€"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Vuokra max</strong>
                  </p>
                  <v-text-field
                    v-model.number="estimatedRentMax"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    type="number"
                    placeholder="Max"
                    suffix="€"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>

        <!-- ACTION BTNS -->
        <v-row class="ma-1" dense justify="center">
          <v-col cols="12" class="text-center">
            <v-btn
              class="white--text mr-3 btn-action"
              :color="settings.actionBtnColor"
              @click="
                setPage(1);
                search();
              "
              >Hae</v-btn
            >
            <v-btn class="white--text" :color="settings.deleteBtnColor" @click="clearFields"
              >Tyhjennä</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],
  props: ["showSearchForm", "showLocation", "showArea", "showEstimatedRent"],

  data() {
    return {
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("account", ["settings", "colors"]),

    locations() {
      return this.settings.locations;
    },

    location: {
      get() {
        return this.$store.state.apartment.searchTerms.location;
      },
      set(val) {
        this.setSearchTerms({ val, field: "location" });
      },
    },
    freeText: {
      get() {
        return this.$store.state.apartment.searchTerms.freeText;
      },
      set(val) {
        this.setSearchTerms({ val, field: "freeText" });
      },
    },
    areaMin: {
      get() {
        return this.$store.state.apartment.searchTerms.areaMin;
      },
      set(val) {
        this.setSearchTerms({ val, field: "areaMin" });
      },
    },
    areaMax: {
      get() {
        return this.$store.state.apartment.searchTerms.areaMax;
      },
      set(val) {
        this.setSearchTerms({ val, field: "areaMax" });
      },
    },
    estimatedRentMin: {
      get() {
        return this.$store.state.apartment.searchTerms.estimatedRentMin;
      },
      set(val) {
        this.setSearchTerms({ val, field: "estimatedRentMin" });
      },
    },
    estimatedRentMax: {
      get() {
        return this.$store.state.apartment.searchTerms.estimatedRentMax;
      },
      set(val) {
        this.setSearchTerms({ val, field: "estimatedRentMax" });
      },
    },
    houseType: {
      get() {
        return this.$store.state.apartment.searchTerms.houseType;
      },
      set(val) {
        this.setSearchTerms({ val, field: "houseType" });
      },
    },
    roomCount: {
      get() {
        return this.$store.state.apartment.searchTerms.roomCount;
      },
      set(val) {
        this.setSearchTerms({ val, field: "roomCount" });
      },
    },

    fieldStyle() {
      return {
        "--fieldColor": this.settings.fieldColor,
      };
    },
  },

  async created() {
    this.$root.$refs.searchFormDefault = this;
  },

  methods: {
    ...mapMutations("apartment", [
      "clearApartments",
      "setApartmentsLoading",
      "setSearchTerms",
      "setPage",
    ]),
    ...mapActions("apartment", ["searchApartments"]),

    search() {
      if (this.$refs.form.validate()) {
        this.setApartmentsLoading(true);
        this.searchApartments(this.$route.query);
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
      }
    },

    formattedLocation(item) {
      const city = item.city;
      const neighborhood = item.neighborhood ? `, ${item.neighborhood}` : "";
      return `${city}${neighborhood}`;
    },

    clearFields() {
      this.$refs.form.reset();
      this.clearApartments();
      this.search();
    },

    focusOut() {
      this.$refs.location_field.blur();
      this.$refs.freeText_field.focus();
    },
  },
};
</script>

<style scoped src="../../assets/css/searchForm.css"></style>
