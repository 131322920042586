<template>
  <v-card :loading="loading" class="h-full">
    <v-card-text class="card-text pl-2">
      <div style="margin-left: 5px" class="details">
        <p class="pt-1" style="margin-bottom: -2px">
          <strong>{{ data.address }}</strong>
        </p>
        <p class="mb-1">{{ data.zipCode }}, {{ data.city }}</p>
      </div>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card-title
            v-if="data.estimatedRent"
            class="price"
            :style="{ color: settings.accentColor }"
            >{{ formatCardPrice(data.estimatedRent) }}/kk</v-card-title
          >

          <v-card-title v-else style="font-size: 21px">-</v-card-title>
        </v-col>

        <v-col cols="6" class="ml-auto">
          <v-card-title class="justify-end area">{{ data.area }} m²</v-card-title>
        </v-col>
      </v-row>

      <v-card-title
        style="
          padding-left: 5px;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3em;
        "
        >{{ data.floorPlan }}</v-card-title
      >

      <v-card-title
        style="
          padding-left: 5px;
          margin-top: -15px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3em;
        "
      >
        {{ data.houseType }}
        <span
          v-if="data.houseType && data.condominium.buildYear"
          style="margin-right: 0.4em; display: inline-block"
          >,</span
        >

        {{ data.condominium.buildYear }}
      </v-card-title>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState } from "vuex";

export default {
  props: ["data"],
  mixins: [mixins],
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState("account", ["settings"]),
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 5px;
  letter-spacing: 0px;
}

.card-text {
  font-size: 95%;
}

.details {
  font-size: 15px;
}

.price {
  font-size: 20px;
  margin-bottom: 3px;
}

.area {
  font-size: 20px;
}

.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
}

@media only screen and (max-width: 1260px) and (min-width: 960px) {
  .v-application .container-wrap {
    font-size: 82%;
  }
  .details {
    font-size: 14px;
  }
  .price {
    font-size: 15px;
  }
  .area {
    font-size: 16px;
  }
  .empty-box-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 790px) and (min-width: 600px) {
  .v-application .container-wrap {
    font-size: 82%;
  }
  .details {
    font-size: 14px;
  }
  .price {
    font-size: 15px;
  }
  .area {
    font-size: 16px;
  }
  .empty-box-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 450px) {
  .v-application .container-wrap {
    font-size: 82%;
  }
  .details {
    font-size: 16px;
  }
  .price {
    font-size: 20px;
  }
  .area {
    font-size: 20px;
  }
  .empty-box-text {
    font-size: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .v-application .container-wrap {
    font-size: 82%;
  }
  .details {
    font-size: 14px;
  }
  .price {
    font-size: 15px;
  }
  .area {
    font-size: 16px;
  }
  .empty-box-text {
    font-size: 12px;
  }
}
</style>
