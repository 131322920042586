<template>
  <v-card :loading="loading" class="h-full container-wrap">
    <v-card-text class="card-text">
      <v-row dense style="min-height: 110px" align="center" justify="center">
        <!-- Image -->
        <v-col cols="4">
          <v-lazy
            v-if="imagesFound(data)"
            v-model="isActive"
            :options="{
              threshold: 0.5,
            }"
            min-height="110"
            transition="fade-transition"
          >
            <v-img
              class="ml-1 ma-0 pa-0 mr-1"
              :src="imageUrl"
              height="110"
              contain
              @error="reloadImage(data)"
            ></v-img>
          </v-lazy>

          <div v-else class="empty-box">
            <p style="font-size: 16px" class="empty-box-text">Ei kuvia</p>
          </div>
        </v-col>

        <!-- Rent -->
        <v-col cols="4" style="padding-right: 3px" align="start">
          <p v-if="data.estimatedRent" class="price" :style="{ color: settings.accentColor }">
            {{ formatCardPrice(data.estimatedRent) }}/kk
          </p>

          <p v-else class="price">-</p>

          <p style="margin-bottom: -1px">{{ data.area }} m²</p>
          <p>{{ data.floorPlan }}</p>
        </v-col>

        <!-- Info -->
        <v-col cols="4" style="padding-left: 0px" align="start">
          <div class="mr-1">
            <p>
              <strong>{{ data.address }}, {{ data.apartmentNumber }}</strong>
            </p>
            <p>{{ data.zipCode }}, {{ data.city }}</p>
            <p>
              {{ data.houseType }}<span v-if="data.houseType && data.condominium.buildYear">, </span
              >{{ data.condominium.buildYear }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState } from "vuex";

export default {
  props: ["data"],

  mixins: [mixins],

  data() {
    return {
      isActive: false,
      loading: false,
      imageUrl: "",
    };
  },
  computed: {
    ...mapState("account", ["settings"]),
  },
  mounted() {
    this.updateImageUrl();
  },

  methods: {
    updateImageUrl() {
      this.imageUrl = this.setThumbnailImage(this.data);
    },

    reloadImage() {
      setTimeout(() => {
        const imageUrl = this.setThumbnailImage(this.data);
        this.imageUrl = imageUrl;
      }, 2500);
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 95%;
}

.v-card__title {
  padding: 5px;
  letter-spacing: 0px;
}

.price {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

@media only screen and (max-width: 600px) {
  .v-application .container-wrap {
    font-size: 82%;
  }
  .price {
    font-size: 14px;
  }

  .empty-box-text {
    font-size: 12px;
  }
}
</style>
