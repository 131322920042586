<template>
  <v-container style="padding-top: 0">
    <!-- SEARCH -->
    <search-apartments
      v-show="showSearchForm"
      :showSearchForm="showSearchForm"
      :showLocation="showLocation"
      :showArea="showArea"
      :showEstimatedRent="showEstimatedRent"
      class="mb-2"
    ></search-apartments>

    <!-- Items per page and pagination -->
    <v-row
      :class="{ 'mt-6': $vuetify.breakpoint.xsOnly && showSearchForm }"
      dense
      no-gutters
      justify="end"
    >
      <v-col cols="5" sm="3" lg="2" xl="1">
        <v-select
          v-model="itemsPerPage"
          dense
          outlined
          :items="totalItems"
          hide-details
          suffix="kpl"
          item-color=""
          :color="settings.primaryColor"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="!loadingApartments && !newSearchNeeded">
      <!-- LIST -->
      <v-row
        v-if="settings.listStyle === 0 || settings.listStyle === 1"
        class="justify-center mt-1"
        no-gutters
        dense
      >
        <v-col
          v-for="(apartment, index) in apartments"
          :key="index"
          cols="12"
          sm="11"
          md="9"
          lg="7"
          style="margin-top: 10px"
          align="center"
        >
          <apartment-list-image-card
            v-if="settings.listStyle === 0"
            class="apartment-container"
            @click.native="$router.push(`/kohteet/${apartment._id}`)"
            :data="apartment"
          ></apartment-list-image-card>

          <apartment-list-card
            v-else
            class="apartment-container"
            @click.native="$router.push(`/kohteet/${apartment._id}`)"
            :data="apartment"
          ></apartment-list-card>
        </v-col>
      </v-row>

      <!-- GRID-->
      <v-row v-if="settings.listStyle === 2 || settings.listStyle === 3" dense>
        <v-col
          v-for="(apartment, index) in apartments"
          :key="index"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          cols="12"
          class="mt-1"
        >
          <apartment-grid-image-card
            v-if="settings.listStyle === 2"
            class="apartment-container"
            @click.native="$router.push(`/kohteet/${apartment._id}`)"
            :data="apartment"
          ></apartment-grid-image-card>

          <apartment-grid-card
            v-else
            class="apartment-container"
            @click.native="$router.push(`/kohteet/${apartment._id}`)"
            :data="apartment"
          ></apartment-grid-card>
        </v-col>
      </v-row>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loadingApartments"
      text="Haetaan kohteita..."
      class="loader"
    ></full-page-loader>

    <!-- Empty list -->
    <div v-if="apartments.length === 0 && !loadingApartments" class="mt-3">
      <h2 class="mb-3 text-center">{{ searchMessage }}</h2>

      <!-- CONTACT FORM -->
      <v-row v-if="settings.showEmptyResultsForm" dense class="justify-center">
        <v-col cols="12" sm="6" md="8" lg="5" xl="4">
          <div
            class="contact-box"
            :style="{
              backgroundColor: settings.contactBackgroundColor,
              border: `1px solid ${settings.contactBorderColor}`,
            }"
          >
            <empty-results-form> </empty-results-form>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Empty list -->
    <div v-if="!loadingApartments && newSearchNeeded" class="text-center mt-3">
      <!-- CONTACT FORM -->
      <v-row dense class="justify-center">
        <v-col cols="12" sm="6" md="8" lg="5" xl="4">
          <h3>Tee haku uusilla asetuksilla!</h3>
        </v-col>
      </v-row>
    </div>

    <!-- Pagination -->
    <v-row v-if="apartments.length > 0 && !newSearchNeeded" justify="center" dense>
      <v-col cols="12" sm="8" lg="4">
        <v-pagination
          v-model="page"
          :color="settings.primaryColor"
          :length="countTotalLength()"
          @input="pageChange"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApartmentGridImageCard from "../components/Apartment/ApartmentGridImageCard.vue";
import ApartmentListImageCard from "../components/Apartment/ApartmentListImageCard.vue";
import ApartmentGridCard from "../components/Apartment/ApartmentGridCard.vue";
import ApartmentListCard from "../components/Apartment/ApartmentListCard.vue";
import SearchApartments from "../components/Apartment/SearchApartments.vue";
import EmptyResultsForm from "../components/Forms/EmptyResultsForm.vue";
import FullPageLoader from "../components/FullPageLoader.vue";
import mixins from "../mixins/mixins";
// import Language from "../components/Language.vue";

export default {
  title: "Vuokrakohteet",

  mixins: [mixins],

  components: {
    // Language,
    ApartmentGridImageCard,
    ApartmentListImageCard,
    ApartmentGridCard,
    ApartmentListCard,
    SearchApartments,
    FullPageLoader,
    EmptyResultsForm,
  },

  data() {
    return {
      totalItems: [8, 16, 32, 64],
    };
  },

  computed: {
    ...mapState("apartment", [
      "apartments",
      "page",
      "itemsPerPage",
      "totalLength",
      "searchTerms",
      "loadingApartments",
      "newSearchNeeded",
    ]),
    ...mapState("account", ["settings"]),

    showSearchForm() {
      return !this.showLocation &&
        !this.showArea &&
        !this.showEstimatedRent &&
        !this.settings.searchComponentItems.includes("freeText") &&
        !this.settings.searchComponentItems.includes("houseType") &&
        !this.settings.searchComponentItems.includes("roomCount")
        ? false
        : true;
    },

    showLocation() {
      return this.$route.query.location ? false : true;
    },

    showArea() {
      return this.$route.query.areaMin || this.$route.query.areaMax ? false : true;
    },

    showEstimatedRent() {
      return this.$route.query.estimatedRentMin || this.$route.query.estimatedRentMax
        ? false
        : true;
    },

    searchMessage: {
      get() {
        return this.$store.state.apartment.searchMessage;
      },
      set(val) {
        this.$store.state.apartment.searchMessage = val;
      },
    },

    page: {
      get() {
        return this.$store.state.apartment.page;
      },
      set(page) {
        this.$store.state.apartment.page = page;
      },
    },

    itemsPerPage: {
      get() {
        return this.$store.state.apartment.itemsPerPage;
      },
      set(itemsPerPage) {
        this.$store.state.apartment.itemsPerPage = itemsPerPage;
      },
    },
  },

  watch: {
    itemsPerPage() {
      this.page = 1;
      this.searchApartments();
    },
  },

  methods: {
    ...mapActions("apartment", ["searchApartments"]),

    pageChange() {
      this.searchApartments();
      this.scrollToBottom();
    },

    countTotalLength() {
      return Math.ceil(this.totalLength / this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.apartment-container:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.3);
}

.loader {
  height: 30vh;
}

.contact-box {
  border-radius: 7px;
  padding: 23px 25px 25px 25px;
}
</style>
