var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-1 mt-2",style:({ color: _vm.settings.contactFieldTextColor })},[_vm._v(" Jätä yhteystietosi niin olemme yhteydessä heti kun sopivia vuokrakohteita vapautuu ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Nimi")])]),_c('v-text-field',{style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"rules":_vm.required,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Email")])]),_c('v-text-field',{style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"rules":_vm.emailRules,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Puhelin")])]),_c('v-text-field',{style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(
        _vm.settings.emptyResultsFormFields.includes('allocation') &&
        _vm.settings.emptyResultsFormValues.allocation
      )?_c('div',[_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Osuus vuokrakohteesta")])]),_vm._l((_vm.getCustomValues(_vm.settings.emptyResultsFormValues.allocation)),function(checkbox,index){return _c('v-checkbox',{key:index + 'a',staticClass:"shrink mr-0 ml-0 mt-0",style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"color":_vm.settings.contactFieldTextColor,"label":checkbox,"value":checkbox,"outlined":"","hide-details":""},model:{value:(_vm.allocation),callback:function ($$v) {_vm.allocation=$$v},expression:"allocation"}})})],2):_vm._e(),(
        _vm.settings.emptyResultsFormFields.includes('area') && _vm.settings.emptyResultsFormValues.area
      )?_c('div',[_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Vuokrakohteen koko")])]),_vm._l((_vm.getCustomValues(_vm.settings.emptyResultsFormValues.area)),function(checkbox,index){return _c('v-checkbox',{key:index + 'a',staticClass:"shrink mr-0 ml-0 mt-0",style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"color":_vm.settings.contactFieldTextColor,"label":checkbox,"value":checkbox,"outlined":"","hide-details":""},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})})],2):_vm._e(),_c('p',{staticClass:"text-label",style:({ color: _vm.settings.contactFieldLabelColor })},[_c('strong',[_vm._v("Viesti")])]),_c('v-textarea',{staticClass:"mb-3",style:([_vm.colors.contactFieldColor, _vm.colors.contactFieldTextColor, _vm.colors.errorColor]),attrs:{"placeholder":"Kirjoita muut toiveesi vuokrakohteeseen liittyen...","outlined":"","dense":"","hide-details":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticStyle:{"position":"absolute","top":"-9999em","left":"-9999em"}},[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-checkbox',{attrs:{"label":"Agree terms"},model:{value:(_vm.termsAgreeCheck),callback:function ($$v) {_vm.termsAgreeCheck=$$v},expression:"termsAgreeCheck"}})],1)],1),_c('v-btn',{staticClass:"white--text mb-3",attrs:{"disabled":!_vm.valid,"color":_vm.settings.actionBtnColor},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" Lähetä ")]),(_vm.sendStatus.message)?_c('div',{staticClass:"message-box",style:({
      border: _vm.sendStatus.error
        ? `1px solid ${_vm.settings.errorColor}`
        : `1px solid ${_vm.settings.successColor}`,
    })},[_c('p',{style:({ color: _vm.sendStatus.error ? _vm.settings.errorColor : _vm.settings.successColor })},[_vm._v(" "+_vm._s(_vm.sendStatus.message)+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }