<template>
  <v-card :loading="loading" class="h-full">
    <v-card-text class="card-text pl-2">
      <div style="margin-left: 5px">
        <p class="pt-1" style="margin-bottom: -2px">
          <strong>{{ data.address }}</strong>
        </p>
        <p>{{ data.zipCode }}, {{ data.city }}</p>
      </div>
      <v-lazy
        v-if="imagesFound(data)"
        v-model="isActive"
        :options="{
          threshold: 0.5,
        }"
        min-height="220"
        transition="fade-transition"
      >
        <v-img :src="imageUrl" height="220" contain @error="reloadImage(data)"></v-img>
      </v-lazy>

      <div v-else class="empty-box">
        <p style="font-size: 22px">Ei kuvia</p>
      </div>

      <v-row class="mt-1" dense no-gutters>
        <v-col cols="6">
          <v-card-title
            :style="{ color: settings.accentColor }"
            v-if="data.estimatedRent"
            style="font-size: 21px; margin-bottom: 3px"
            >{{ formatCardPrice(data.estimatedRent) }}/kk</v-card-title
          >
          <v-card-title v-else style="font-size: 21px">-</v-card-title>
        </v-col>

        <v-col cols="6" class="ml-auto">
          <v-card-title class="justify-end" style="font-size: 21px"
            >{{ data.area }} m²</v-card-title
          >
        </v-col>
      </v-row>

      <v-card-title
        style="
          padding-left: 5px;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3em;
        "
        >{{ data.floorPlan }}</v-card-title
      >

      <v-card-title
        style="
          padding-left: 5px;
          margin-top: -15px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3em;
        "
      >
        {{ data.houseType }}
        <span
          v-if="data.houseType && data.condominium.buildYear"
          style="margin-right: 0.4em; display: inline-block"
          >,</span
        >

        {{ data.condominium.buildYear }}
      </v-card-title>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState } from "vuex";

export default {
  props: ["data"],

  mixins: [mixins],

  data() {
    return {
      imageUrl: "",
      isActive: false,
      loading: false,
    };
  },

  computed: {
    ...mapState("account", ["settings"]),
  },

  mounted() {
    this.updateImageUrl();
  },

  methods: {
    updateImageUrl() {
      this.imageUrl = this.setThumbnailImage(this.data);
    },

    reloadImage() {
      setTimeout(() => {
        const imageUrl = this.setThumbnailImage(this.data);
        this.imageUrl = imageUrl;
      }, 2500);
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 95%;
}

.v-card__title {
  padding: 5px;
  letter-spacing: 0px;
}

.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
}
</style>
