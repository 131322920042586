<template>
  <div
    :style="{
      background: settings.searchBackgroundColor,
    }"
  >
    <v-row class="py-3 px-sm-2" dense no-gutters justify="center">
      <v-col cols="12" md="12" lg="9" justify="center">
        <v-form ref="form">
          <v-row dense class="ma-1" justify="center">
            <!-- Condominium // address -->
            <v-col cols="12" sm="6" md="4">
              <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                <strong>Sijainti</strong>
              </p>
              <v-autocomplete
                class="my-field"
                v-model="location"
                :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                :items="locations"
                :item-text="formattedLocation"
                :item-value="JSON.stringify(location)"
                outlined
                dense
                hide-details
                item-color=""
                clearable
              >
              </v-autocomplete>
            </v-col>

            <!-- Area -->
            <v-col cols="12" sm="6" md="5">
              <v-row no-gutters>
                <v-col class="pr-1">
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Pinta-ala min</strong>
                  </p>
                  <v-text-field
                    v-model.number="areaMin"
                    type="number"
                    placeholder="Min"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    suffix="m²"
                    outlined
                    dense
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col>
                  <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                    <strong>Pinta-ala max</strong>
                  </p>
                  <v-text-field
                    v-model.number="areaMax"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    type="number"
                    placeholder="Max"
                    suffix="m²"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Rent time -->
          <v-row dense class="ma-1" justify="center">
            <v-col cols="12" sm="6" md="4">
              <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                <strong>Alkaen</strong>
              </p>

              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="dateFormattedStart"
                    :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :rules="required"
                    hide-details
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  first-day-of-week="1"
                  @input="menu = false"
                  :color="settings.primaryColor"
                  :allowed-dates="allowedDates"
                  @change="setNewSearchNeeded(true)"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <p :style="{ color: settings.fieldLabelColor }" class="text-label">
                <strong>Vuokra-aika</strong>
              </p>
              <v-select
                v-model="rentTime"
                :style="[colors.fieldColor, colors.fieldTextColor, colors.errorColor]"
                :items="fixedRentTimes"
                item-value="val"
                item-text="text"
                :rules="required"
                outlined
                dense
                hide-details
                item-color=""
                @change="setNewSearchNeeded(true)"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  <span :style="{ color: settings.fieldTextColor }">{{ data.item.text }}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  <span style="font-weight: 600; font-size: 13px">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>

        <!--  Search -->
        <v-row class="ma-1" dense justify="center">
          <v-col cols="12" md="9">
            <v-btn
              class="white--text mr-3"
              :color="settings.actionBtnColor"
              @click="
                setPage(1);
                search();
              "
              >Hae</v-btn
            >

            <v-btn class="white--text" :color="settings.deleteBtnColor" @click="clearFields"
              >Tyhjennä</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import moment from "moment";

export default {
  mixins: [mixins],

  data() {
    return {
      menu: false,
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  async created() {
    this.$root.$refs.searchFormReservations = this;
  },

  computed: {
    ...mapState("account", ["settings", "colors"]),

    fixedRentTimes() {
      return this.settings.fixedRentTimes;
    },

    locations() {
      return this.settings.locations;
    },

    location: {
      get() {
        return this.$store.state.apartment.searchTerms.location;
      },
      set(val) {
        this.setSearchTerms({ val, field: "location" });
      },
    },

    startDate: {
      get() {
        return this.$store.state.apartment.searchTerms.startDate;
      },
      set(val) {
        this.setSearchTerms({ val, field: "startDate" });
      },
    },

    rentTime: {
      get() {
        return this.$store.state.apartment.searchTerms.rentTime;
      },
      set(val) {
        this.setSearchTerms({ val, field: "rentTime" });
      },
    },

    areaMin: {
      get() {
        return this.$store.state.apartment.searchTerms.areaMin;
      },
      set(val) {
        this.setSearchTerms({ val, field: "areaMin" });
      },
    },

    areaMax: {
      get() {
        return this.$store.state.apartment.searchTerms.areaMax;
      },
      set(val) {
        this.setSearchTerms({ val, field: "areaMax" });
      },
    },

    searchMessage: {
      get() {
        return this.$store.state.apartment.searchMessage;
      },
      set(val) {
        this.$store.state.apartment.searchMessage = val;
      },
    },

    dateFormattedStart() {
      return this.formatDate(this.startDate);
    },
  },

  // mounted() {
  //   this.setFieldValues();
  // },

  methods: {
    ...mapMutations("apartment", [
      "setSearchTerms",
      "clearApartments",
      "setApartmentsLoading",
      "setPage",
    ]),
    ...mapActions("apartment", ["searchApartments"]),
    ...mapMutations("apartment", ["setNewSearchNeeded"]),

    setFieldValues() {
      if (this.startDate) {
        if (moment(this.startDate).isBefore(new Date())) {
          this.startDate = moment(new Date())
            .add(this.settings.reserveBefore, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        this.startDate = moment(new Date())
          .add(this.settings.reserveBefore, "days")
          .format("YYYY-MM-DD");
      }

      if (!this.rentTime) {
        this.rentTime = this.fixedRentTimes[0].val;
      }

      this.$nextTick(() => {
        this.search();
      });
    },

    search() {
      if (this.$refs.form.validate()) {
        this.setApartmentsLoading(true);
        this.searchApartments(this.$route.query);
        this.setNewSearchNeeded(false);
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
      }
    },

    formattedLocation(item) {
      const city = item.city;
      const neighborhood = item.neighborhood ? `, ${item.neighborhood}` : "";
      return `${city}${neighborhood}`;
    },

    allowedDates(val) {
      const firstRentDay = new Date(
        moment(new Date()).add(this.settings.reserveBefore, "days").format("YYYY-MM-DD")
      );
      const show = new Date(val).getTime() >= firstRentDay ? true : false;
      return show;
    },

    clearFields() {
      this.$refs.form.reset();
      this.clearApartments();
      this.startDate = "";
      this.searchMessage = "Etsi vapaita kohteita";
      this.setNewSearchNeeded(false);
    },
  },
};
</script>

<style scoped src="../../assets/css/searchForm.css"></style>
