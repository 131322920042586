<template>
  <div v-resize="onResize">
    <!-- Searchform default -->
    <search-form-default
      ref="searchFormDefault"
      v-if="settings.type === 0"
      :style="{
        border: `1px solid ${settings.searchBorderColor}`,
      }"
      :showSearchForm="showSearchForm"
      :showLocation="showLocation"
      :showArea="showArea"
      :showEstimatedRent="showEstimatedRent"
      class="search-container d-none d-sm-flex"
    ></search-form-default>

    <!-- Searchform reservations-->
    <search-form-reservations
      ref="searchFormReservations"
      v-if="settings.type === 1"
      :style="{
        border: `1px solid ${settings.searchBorderColor}`,
      }"
      class="search-container d-none d-sm-flex"
    ></search-form-reservations>

    <v-navigation-drawer
      v-if="drawer"
      v-model="drawer"
      fixed
      class="elevation-1 rounded"
      :color="$store.state.account.settings.searchBackgroundColor"
    >
      <!-- Searchform default-->
      <search-form-default
        v-if="settings.type === 0"
        :showSearchForm="showSearchForm"
        :showLocation="showLocation"
        :showArea="showArea"
        :showEstimatedRent="showEstimatedRent"
      ></search-form-default>

      <!-- Searchform reservations-->
      <search-form-reservations v-if="settings.type === 1"></search-form-reservations>
    </v-navigation-drawer>

    <v-app-bar
      v-if="showSearchForm"
      :color="$store.state.account.settings.searchBackgroundColor"
      class="hidden-sm-and-up"
      fixed
      rounded
      outlined
      dense
      bottom
      elevate-on-scroll
    >
      <v-app-bar-nav-icon
        :color="settings.fieldLabelColor"
        @click="drawer = !drawer"
        class="ml-1"
      ></v-app-bar-nav-icon>
      <div class="title font-weight-bold" :style="{ color: settings.fieldLabelColor }">Haku</div>
    </v-app-bar>
  </div>
</template>

<script>
import SearchFormDefault from "../Forms/SearchFormDefault.vue";
import SearchFormReservations from "../Forms/SearchFormReservations.vue";
import { mapState } from "vuex";

export default {
  components: { SearchFormDefault, SearchFormReservations },
  props: ["showSearchForm", "showLocation", "showArea", "showEstimatedRent"],

  data() {
    return {
      drawer: false,
      windowSize: null,
    };
  },

  mounted() {
    if (this.settings.type == 0) this.$refs.searchFormDefault.search();
    if (this.settings.type == 1) this.$refs.searchFormReservations.setFieldValues();
  },

  computed: {
    ...mapState("account", ["settings"]),
  },

  watch: {
    windowSize() {
      if (this.windowSize >= 600) {
        this.drawer = false;
      }
    },
  },

  methods: {
    onResize() {
      this.windowSize = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.search-container {
  border-radius: 5px;
}
</style>
